import { FC } from 'react';
import IconProps from './IconProps';

const DashboardIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00098 5.9825C4.00098 4.8876 4.88857 4 5.98348 4H7.96598C9.06089 4 9.94848 4.8876 9.94848 5.9825V7.965C9.94848 9.05991 9.06089 9.9475 7.96598 9.9475H5.98348C4.88857 9.9475 4.00098 9.05991 4.00098 7.965V5.9825Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9111 5.9825C13.9111 4.8876 14.7987 4 15.8936 4H17.8761C18.9711 4 19.8586 4.8876 19.8586 5.9825V7.965C19.8586 9.05991 18.9711 9.9475 17.8761 9.9475H15.8936C14.7987 9.9475 13.9111 9.05991 13.9111 7.965V5.9825Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 15.895C4 14.8 4.8876 13.9125 5.9825 13.9125H7.965C9.05991 13.9125 9.9475 14.8 9.9475 15.895V17.8775C9.9475 18.9724 9.05991 19.86 7.965 19.86H5.9825C4.8876 19.86 4 18.9724 4 17.8775V15.895Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9111 15.895C13.9111 14.8 14.7987 13.9125 15.8936 13.9125H17.8761C18.9711 13.9125 19.8586 14.8 19.8586 15.895V17.8775C19.8586 18.9724 18.9711 19.86 17.8761 19.86H15.8936C14.7987 19.86 13.9111 18.9724 13.9111 17.8775V15.895Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DashboardIcon;
